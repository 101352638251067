.profile__user-about {
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 425px) {
  .profile__user-about {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}
