.profile__container {
  display: flex;
  align-items: baseline;
  gap: 18px;
}

@media screen and (max-width: 767px) {
  .profile__container {
    justify-content: center;
  }
}
