.popup__input {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5px;
  padding: 0px 0px 8px 0px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  font-size: 14px;
  line-height: 1.21;
  font-weight: 400;
  color: #000;
  /* font-size: 14px;
  line-height: 17px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 1px 2px 13px 2px; */
}

/* .popup__input:first-child {
  margin: 0 0 30px 0;
} */

.popup__input:focus {
  outline: none;
}
.popup__input::-moz-focus-inner {
  border: 0;
}
