.popup__input_type_auth {
  background-color: transparent;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 30px;
  color: #fff;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  line-height: 16.94px;
}

.popup__input_type_auth::placeholder {
  color: #ccc;
}

.popup__input_type_auth:last-of-type {
  margin-bottom: 0;
}
