.elements__text {
  font-weight: 900;
  font-size: 24px;
  line-height: 38px;
  max-width: 200px;
  margin: 0;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
