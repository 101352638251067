.popup__submit {
  background: #000000;
  border-radius: 2px;
  color: white;
  max-width: 358px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  border: none;
  width: 100%;
  margin-top: 48px;
  padding: 0;
  transition: 0.2s linear;
  opacity: 1;
}

.popup__submit:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media screen and (max-width: 460px) {
  .popup__submit {
    font-size: 14px;
    line-height: 17px;
    margin-top: 45px;
  }
}
