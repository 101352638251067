.popup__container {
  display: flex;
  flex-direction: column;
  max-width: 430px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 36px;
  box-sizing: border-box;
  position: relative;
}

@media screen and (max-width: 460px) {
  .popup__container {
    max-width: 282px;
    padding: 22px;
  }
}
