.footer__author {
  margin: 0 auto;
  font-size: 18px;
  line-height: 22px;
  color: #545454;
}

@media screen and (max-width: 425px) {
  .footer__author {
    font-size: 14px;
    line-height: 17px;
  }
}
