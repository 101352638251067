.profile__user-name {
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 425px) {
  .profile__user-name {
    font-size: 27px;
    line-height: 33px;
    text-align: center;
    margin-left: 8vw;
  }
}
