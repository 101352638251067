.auth__submit-button {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  color: #000;
  width: 100%;
  min-height: 50px;
  margin-bottom: 15px;
  margin-top: 216px;
  background-color: #fff;
  border-radius: 2px;
  border: none;
  transition: 0.2s;
}

.auth__submit-button:hover {
  opacity: 0.85;
  cursor: pointer;
}
