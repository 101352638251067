.profile__info {
  max-width: 336px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .profile__info {
    margin: 0 auto;
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .profile__info {
    gap: 2px;
    max-width: 80%;
  }
}
