.footer {
  max-width: 880px;
  margin: 0 auto;
  padding: 67px 0 60px 0;
}

@media screen and (max-width: 768px) {
  .footer {
    width: 88%;
    padding: 48px 0 36px 0;
  }
}
