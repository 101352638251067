.profile__avatar-button {
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  object-fit: cover;
  background-color: transparent;
  border: none;
  transition: 0.2s;
}

.profile__avatar-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url(../../../images/Rectangle.png);
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
}
