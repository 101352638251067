.profile__edit-button {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background-image: url(../../../images/pencil.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: 0.2s linear;
  opacity: 1;
}

.profile__edit-button:hover {
  opacity: 0.6;
  cursor: pointer;
}
@media screen and (max-width: 374px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
  }
}
