.profile {
  max-width: 880px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 40px 0 51px 0;
  align-items: center;
  gap:30px;
}

@media screen and (max-width: 767px) {
  .profile {
    flex-direction: column;
    padding: 42px 0 36px 0;
    gap: 0px;
  }
}
