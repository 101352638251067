.popup__title {
  color: black;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0 0 54px 0;
}

@media screen and (max-width: 460px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 75px 0;
  }
}
