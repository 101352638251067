.auth__title {
  margin: 60px 0 50px 0;
  max-width: 100%;
  padding: 0;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  color: #fff;
  text-align: center;
}
