.auth__link {
  color: #fff;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
}

.auth__link:hover {
  cursor: pointer;
  opacity: 0.6;
}
