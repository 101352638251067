.header__logo {
  width: 142px;
  height: 33px;
}

@media screen and (max-width: 926px) {
  .header__logo {
    margin-left: 27px;
  }
}

@media screen and (max-width: 425px) {
  .header__logo {
    width: 103.74px;
    height: 24.4px;
  }
}
