.header {
  max-width: 880px;
  margin: 0 auto;
  padding: 45px 0 41px 0;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 425px) {
  .header {
    padding: 28px 0 31px 0;
  }
}
