.profile__add-button {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  width: 150px;
  height: 50px;
  align-self: center;
  background-image: url(../../../images/plus.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: 0.2s linear;
  opacity: 1;
}

.profile__add-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .profile__add-button {
    margin-top: 36px;
    width: 282px;
    background-size: 16px 16px;
  }
}
